//
// reboot.scss
//


body {
  letter-spacing: 0.02rem;
  font-family: $font-family-base;
}

// a

a {
  text-decoration: none !important;
  display: inline-block;

  &:focus {
    outline: none;
  }
}

.row>* {
  position: relative;
}

// Forms
label {
  font-weight: $font-weight-semibold;
  font-size: $font-size-sm;
}

// Buttons
button:focus {
  outline: none;
}

// blockquote

.blockquote {
  padding: 10px 20px;
  margin-bottom: 20px;
  border-left: 4px solid $gray-200;

  &.blockquote-reverse {
    border-left: 0;
    border-right: 4px solid $gray-200;
    text-align: right;
  }
}

// zindex
.zindex-1 {
  z-index: 1;
}

.zindex-5 {
  z-index: 5;
}

.zindex-10 {
  z-index: 10;
}

.zindex-20 {
  z-index: 20;
}
